import { Link, useNavigate } from "react-router-dom";
import { dispatch } from "redux/store";
import { Controller, useForm } from "react-hook-form";
import axios from "../../utils/axios";
import { resetCart } from "redux/slices/cart";
import {
  AMERIABANK_URL,
  HAS_CREDIT_CART_PAYMENT,
  PAYMENT_METHODS,
} from "utils/config";
import { openSnackbar } from "redux/slices/ui";
import React, { useEffect, useState } from "react";
import OrderTime from "./OrderTime";
import PhoneInput from "react-phone-number-input";
import useAuth from "hooks/useAuth";
import useCart from "../../hooks/useCart";

export default function Checkout() {
  const { isAuthenticated, user } = useAuth();
  const { subtotal, delivery, total, cartItems } = useCart();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState();
  const { loginRegister } = useAuth();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      user: {
        phone: "",
        password: "",
      },
      address: {
        country: "Հայաստան",
        city: "Երևան",
        address_line: "",
      },
      payment_method: HAS_CREDIT_CART_PAYMENT ? "credit_card" : "cash",
      date_time: {
        date: "",
        time: "",
      },
    },
  });

  useEffect(() => {
    setPhone(user?.phone);
  }, [user?.phone]);

  const onSubmit = async (data) => {
    setLoading(true);

    if (!isAuthenticated) {
      try {
        await loginRegister(phone, data.user.password, cartItems);
      } catch (error) {
        if (error.phone) {
          setError("user.phone", { message: error.phone[0] });
        }
        if (error.password) {
          setError("user.password", { message: error.password[0] });
        }
        if (error.detail) {
          setError("user.password", { message: error.detail });
        }
        setLoading(false);
        return;
      }
    }

    delete data.user;
    data.delivery_time = `${data.date_time.date}T${data.date_time.time}`;
    delete data.date_time;
    try {
      const response = await axios.post("/orders/create/", data);
      if (data.payment_method === "credit_card") {
        const { payment_id } = response.data;
        window.location.href = `${AMERIABANK_URL}Payments/Pay?id=${payment_id}&lang=am`;
      } else if (data.payment_method === "cash") {
        dispatch(resetCart());
        navigate("/");
        dispatch(openSnackbar({ message: "Պատվերը ստեղծված է" }));
      }
    } catch (error) {
      dispatch(
        openSnackbar({ message: error.length && error[0], type: "error" }),
      );
      setLoading(false);
    }
  };

  return (
    <div className="wrapper" id="wrapper">
      <div className="ht__bradcaump__area bg-image--18">
        <div className="ht__bradcaump__wrap d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="bradcaump__inner text-center">
                  <h2 className="bradcaump-title">Վճարում</h2>
                  <nav className="bradcaump-inner">
                    <Link className="breadcrumb-item" to="/">
                      Գլխավոր
                    </Link>
                    <span className="brd-separetor">
                      <i className="zmdi zmdi-long-arrow-right"></i>
                    </span>
                    <span className="breadcrumb-item active">Վճարում</span>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section
        className="htc__checkout bg--white"
        style={{ marginTop: "30px" }}
      >
        <div className="checkout-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 mb-30">
                <div id="checkout-accordion">
                  {!isAuthenticated && (
                    <div className="single-accordion">
                      <a
                        className="accordion-head"
                        data-bs-toggle="collapse"
                        data-parent="#checkout-accordion"
                        href="#user-details"
                      >
                        1. Օգտվողի մուտքային տվյալներ
                      </a>
                      <div id="user-details" className="collapse show">
                        <div className="accordion-body user-details fix">
                          <div className="billing-form checkout-form">
                            <div className="row">
                              <div className="col-12 mb--20">
                                <Controller
                                  name="user.phone"
                                  rules={{
                                    required: "Այս դաշտը պետք է լրացված լինի",
                                  }}
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <PhoneInput
                                        {...field}
                                        defaultCountry="AM"
                                        placeholder="Հեռախոսահամար"
                                        value={phone}
                                        onChange={(e) => {
                                          clearErrors("user.phone");
                                          setValue("user.phone", e);
                                          setPhone(e);
                                        }}
                                      />
                                      {errors.user?.phone && (
                                        <small className="text-danger">
                                          {errors.user.phone.message}
                                        </small>
                                      )}
                                    </>
                                  )}
                                />
                              </div>
                              <div className="col-12 mb--20">
                                <Controller
                                  name="user.password"
                                  rules={{
                                    required: "Այս դաշտը պետք է լրացված լինի",
                                  }}
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <input
                                        {...field}
                                        type="password"
                                        placeholder="Ստեղծել գաղտնաբառ"
                                      />
                                      {errors.user?.password && (
                                        <small className="text-danger">
                                          {errors.user.password.message}
                                        </small>
                                      )}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="single-accordion">
                    <a
                      className="accordion-head"
                      data-bs-toggle="collapse"
                      data-parent="#checkout-accordion"
                      href="#billing-method"
                    >
                      {1 + (isAuthenticated ? 0 : 1)}. Առաքման հասցե
                    </a>
                    <div id="billing-method" className="collapse show">
                      <div className="accordion-body billing-method fix">
                        <div className="billing-form checkout-form">
                          <div className="row">
                            <div className="col-12 mb--20">
                              <Controller
                                name="address.country"
                                control={control}
                                render={({ field }) => (
                                  <input {...field} disabled />
                                )}
                              />
                            </div>
                            <div className="col-12 mb--20">
                              <Controller
                                name="address.city"
                                control={control}
                                render={({ field }) => (
                                  <input {...field} disabled />
                                )}
                              />
                            </div>
                            <div className="col-12 mb--20">
                              <Controller
                                name="address.address_line"
                                control={control}
                                rules={{
                                  required: "Այս դաշտը պետք է լրացված լինի",
                                }}
                                render={({ field }) => (
                                  <>
                                    <input {...field} placeholder="Հասցե" />
                                    {errors.address?.address_line && (
                                      <small className="text-danger">
                                        {errors.address.address_line.message}
                                      </small>
                                    )}
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="single-accordion">
                    <a
                      className="accordion-head"
                      data-bs-toggle="collapse"
                      data-parent="#checkout-accordion"
                      href="#order-time"
                    >
                      {2 + (isAuthenticated ? 0 : 1)}. Առաքման ժամանակ
                    </a>
                    <div id="order-time" className="collapse show">
                      <div className="accordion-body payment-method fix">
                        <OrderTime control={control} setValue={setValue} />
                      </div>
                    </div>
                  </div>
                  <div className="single-accordion">
                    <a
                      className="accordion-head"
                      data-bs-toggle="collapse"
                      data-parent="#checkout-accordion"
                      href="#payment-method"
                    >
                      {3 + (isAuthenticated ? 0 : 1)}. Վճարման եղանակ
                    </a>
                    <div id="payment-method" className="collapse show">
                      <div className="accordion-body payment-method fix">
                        <ul className="payment-method-list">
                          {HAS_CREDIT_CART_PAYMENT && (
                            <li
                              className={
                                watch("payment_method") === "credit_card"
                                  ? "active"
                                  : ""
                              }
                              onClick={() =>
                                setValue("payment_method", "credit_card")
                              }
                            >
                              {PAYMENT_METHODS.credit_card}
                            </li>
                          )}
                          <li
                            className={
                              watch("payment_method") === "cash" ? "active" : ""
                            }
                            onClick={() => setValue("payment_method", "cash")}
                          >
                            {PAYMENT_METHODS.cash}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-12 mb-30"
                style={{ marginBottom: "30px" }}
              >
                <div className="order-details-wrapper">
                  <h2>ձեր պատվերը</h2>
                  <div className="order-details">
                    <ul>
                      <li>
                        <p className="strong">Ապրանք</p>
                        <p className="strong">ընդհանուր</p>
                      </li>
                      {cartItems.map((item, index) => (
                        <li key={index}>
                          <p>
                            {item.product.name} x{item.quantity}
                          </p>
                          <p>{item.product.price * item.quantity}֏</p>
                        </li>
                      ))}
                      <li>
                        <p className="strong">Ենթագումար</p>
                        <p className="strong">{subtotal}֏</p>
                      </li>
                      <li>
                        <p className="strong">Առաքման վճար</p>
                        <p className="strong">{delivery}֏</p>
                      </li>
                      <li>
                        <p className="strong">Ընդամենը</p>
                        <p className="strong">{total}֏</p>
                      </li>
                      <li>
                        <button
                          className="food__btn"
                          disabled={loading}
                          onClick={handleSubmit(onSubmit)}
                        >
                          պատվիրել
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
